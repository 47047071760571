
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartWellness extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyWellness;

    localValue: PropertyWellness = {
        pool: null,
        sauna: null,
        jacuzzi: null,
        steam_bath: null,
    };

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyWellness) {
        this.localValue = newValue;
    }
}
